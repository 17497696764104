import React, { Component } from "react";
import "../index.css";
//Need this to switch pages
//V6 React Router Dom Updates
import { Link } from "react-router-dom";
//Need these for font awesome icons
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import {
  faYoutube,
  faTwitter,
  faInstagram,
  faFacebookSquare,
} from "@fortawesome/free-brands-svg-icons";
library.add(faBars, faYoutube, faInstagram, faTwitter, faFacebookSquare); //import font awesome icons

class NavBar extends Component {
  render() {
    return (
      <header className="header">
        <nav className="nav-bar bd-grid">
          <div className="open-slide">
            <FontAwesomeIcon
              icon="bars"
              className="side-menu-icon"
              onClick={openSlideMenu}
            />
            {/* Navigation for within the side menu */}
            <div className="side-nav" onClick={closeSlideMenu}>
              <button className="btn-close" onClick={closeSlideMenu}>
                &times;
              </button>
              <Link
                to="/"
                className="nav__logo nav__link"
                id="logo"
                onClick={closeSlideMenu}
              >
                Bay Area Corgis
              </Link>
              <Link
                to="/available-puppies"
                className="nav__link"
                onClick={closeSlideMenu}
              >
                Available Puppies
              </Link>
              <Link
                to="/stud-service"
                className="nav__link"
                onClick={closeSlideMenu}
              >
                Stud Service
              </Link>
              <Link
                to="/about-us"
                className="nav__link"
                onClick={closeSlideMenu}
              >
                About Us
              </Link>
              <Link
                to="/past-puppies"
                onClick={closeSlideMenu}
                className="nav__link"
              >
                Past Puppies
              </Link>
              <Link
                to="/our-customers"
                onClick={closeSlideMenu}
                className="nav__link"
              >
                Our Customers
              </Link>
              <Link
                to="/contact"
                onClick={closeSlideMenu}
                className="nav__link"
              >
                Contact
              </Link>
              <Link to="/blog" onClick={closeSlideMenu} className="nav__link">
                Blog
              </Link>
              <a
                id="youtube"
                onClick={closeSlideMenu}
                className="nav__link"
                target="_blank"
                rel="noreferrer noopener"
                href="https://www.youtube.com/channel/UC5vRVN2AK60DuvUfrF59KYA/?guided_help_flow=5"
              >
                <FontAwesomeIcon icon={faYoutube} />
              </a>
              <a
                id="instagram"
                onClick={closeSlideMenu}
                className="nav__link"
                target="_blank"
                rel="noreferrer noopener"
                href="https://www.instagram.com/bayareacorgi/"
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>
              <a
                id="twitter"
                onClick={closeSlideMenu}
                className="nav__link"
                target="_blank"
                rel="noreferrer noopener"
                href="https://twitter.com/BayAreaCorgis"
              >
                <FontAwesomeIcon icon={faTwitter} />
              </a>
              <a
                id="facebook"
                onClick={closeSlideMenu}
                className="nav__link"
                target="_blank"
                rel="noreferrer noopener"
                href="https://www.facebook.com/BayAreaCorgis/"
              >
                <FontAwesomeIcon icon={faFacebookSquare} />
              </a>
            </div>

            <div className="nav-business-name-container">
              {/* Backslash directs to home */}
              <Link to="/">
                <p className="nav__logo nav__link" id="logo">
                  Bay Area Corgis
                </p>
              </Link>
            </div>
          </div>

          <div className="nav__menu">
            <ul className="nav__list">
              <li className="nav__item">
                <Link to="/available-puppies" className="nav__link">
                  Available Puppies
                </Link>
              </li>
              <li className="nav__item">
                <Link to="/stud-service" className="nav__link">
                  Stud Service
                </Link>
              </li>
              <li className="nav__item">
                <Link to="/about-us" className="nav__link">
                  About Us
                </Link>
              </li>
              <li className="nav__item">
                <Link to="/our-customers" className="nav__link">
                  Our Customers
                </Link>
              </li>
              <li className="nav__item">
                <Link to="/contact" className="nav__link">
                  Contact
                </Link>
              </li>
              <li className="nav__item">
                <a
                  id="instagram"
                  onClick={closeSlideMenu}
                  className="nav__link"
                  target="_blank"
                  rel="noreferrer noopener"
                  href="https://www.instagram.com/bayareacorgi/"
                >
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    );
  }
}

function openSlideMenu() {
  document.querySelector(".side-nav").style.width = "250px";
}
function closeSlideMenu() {
  document.querySelector(".side-nav").style.width = "0";
}

// function openSlideMenu() {
//     (document.querySelector(".side-nav").style.width = "250px"), (document.getElementById("main").style.marginRight = "250px");
// }
// function closeSlideMenu() {
//     (document.querySelector(".side-nav").style.width = "0"), (document.getElementById("main").style.marginRight = "0");
// }

export default NavBar;
